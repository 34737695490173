import type { H3Event } from 'h3'
import { emailOTPClient, inferAdditionalFields } from 'better-auth/client/plugins'
import { createAuthClient } from 'better-auth/vue'

export function useAuthClient() {
  const runtimeConfig = useRuntimeConfig()

  return computed(() => {
    return makeAuthClient(runtimeConfig.public.backendUrl)
  })
}

export function makeAuthClient(baseURL: string, event?: H3Event) {
  const options = event
    ? {
        fetchOptions: {
          headers: event.headers,
        },
      }
    : undefined

  return createAuthClient({
    ...options,
    baseURL,
    plugins: [
      emailOTPClient(),
      inferAdditionalFields<{
        user: {
          firstName: {
            type: 'string'
          }
          lastName: {
            type: 'string'
          }
        }
      }>(),
    ],
  })
}
